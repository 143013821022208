<template>
  <div class="section" id="carousel">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12">
          <el-carousel>
            <el-carousel-item>
              <img class="d-block" src="../../assets/images/swiper-slide-1.jpg" alt="First slide" />
              <div class="banner-txt">
                <h5>AMAZING DESTINATIONS</h5>
                <p>We offer a variety of destinations to travel to.</p>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <img class="d-block" src="../../assets/images/swiper-slide-2.jpg" alt="Second slide" />
              <div class="banner-txt">
                <h5>THE TRIP OF YOUR DREAM</h5>
                <p>Our travel agency is ready to offer you an exciting vacation that is designed to fit your own needs and wishes. Whether it’s an exotic cruise or a trip to your favorite resort, you will surely have the best experience.</p>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <img class="d-block" src="../../assets/images/swiper-slide-3.jpg" alt="Third slide" />
              <div class="banner-txt">
                <h5>UNIQUE TRAVEL INSIGHTS</h5>
                <p>Our team is ready to provide you with unique weekly travel insights that include photos, videos, and articles about untravelled tourist paths. We know everything about the places you’ve never been to!</p>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Carousel, CarouselItem } from 'element-ui';

export default {
  components: {
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem
  }
};
</script>
<style>
@media screen and (max-width: 1200px) {
  #carousel{
    display: none;
  }
}

#carousel .container {
  margin: 0 !important;
  padding: 0 !important;
}
#carousel {
  padding: 0 !important;
  margin-top: 70px !important;
}
.el-carousel {
  width: 100vw;
}
#carousel img {
  object-fit: cover;
}
.banner-txt {
  z-index: 200;
  position: absolute;
  top: 20vh;
  left: 5vw;
  width: 30vw;
}
.banner-txt h5 {
  font-weight: 600;
  font-size: 40px;
  text-align: left;
}
.banner-txt p {
  text-align: left;
  font-weight: 500;
  font-size: 20px;
}
.el-carousel__container {
  height: 40rem;
}
</style>
