<template>
  <div>
    <div class="page-header clear-filter" filter-color="orange">
      <!-- <parallax
        class="page-header-image"
        style="background-image:url('img/header.jpg')"
      >
      
      </parallax> -->
      <carousel-section></carousel-section>
    </div>
    <div class="main">
      <div class="company-profile">
      <h5><span>WYC FUNTRIP</span> offers affordable Package Tours for locals and internationals, Airline Ticketing for domestic and international, Passport Documentation, Company Excursions, Van Rentals, Hotel reservation/arrangement and other travel documentation.</h5>
      </div>
      <hr/>
      <package-section></package-section>
      <!-- <basic-elements></basic-elements> -->
    </div>
    <services-section/>
    <!-- <navigation></navigation>
    <tabs-section></tabs-section>
    <progress-pagination></progress-pagination>
    <notifications></notifications>
    <typography></typography>
    <javascript-components></javascript-components>
    <carousel-section></carousel-section>
    <nucleo-icons-section></nucleo-icons-section> -->
    <!-- <div class="section">
      <div class="container text-center">
        <div class="row justify-content-md-center">
          <div class="col-md-12 col-lg-8">
            <h2 class="title">Completed with examples</h2>
            <h5 class="description">
              The kit comes with three pre-built pages to help you get started
              faster. You can change the text and images and you're good to go.
              More importantly, looking at them will give you a picture of what
              you can built with this powerful Bootstrap 4 ui kit.
            </h5>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <signup-form></signup-form>
    <examples-section></examples-section>
    <download-section></download-section> -->
  </div>
</template>
<script>
import BasicElements from './components/BasicElementsSection';
import Navigation from './components/Navigation';
import TabsSection from './components/Tabs';
import ProgressPagination from './components/ProgressPagination';
import Notifications from './components/Notifications';
import Typography from './components/Typography';
import JavascriptComponents from './components/JavascriptComponents';
import CarouselSection from './components/CarouselSection';
import NucleoIconsSection from './components/NucleoIconsSection';
import SignupForm from './components/SignupForm';
import ExamplesSection from './components/ExamplesSection';
import DownloadSection from './components/DownloadSection';
import PackageSection from './components/PackageSection';
import ServicesSection from './components/ServicesSection';

export default {
  name: 'index',
  bodyClass: 'index-page',
  components: {
    // BasicElements,
    // Navigation,
    // TabsSection,
    // ProgressPagination,
    // Notifications,
    // Typography,
    // JavascriptComponents,
    // NucleoIconsSection,
    CarouselSection,
    PackageSection,
    ServicesSection
    // SignupForm,
    // ExamplesSection,
    // DownloadSection
  },
};
</script>
<style>
@media screen and (max-width: 1200px) {
  .company-profile {
    padding-top: 100px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-bottom: 0 !important;
  }
  .company-profile h5 {
    font-size: 1rem !important;
  }
}
.page-header {
  min-height: unset !important;
  max-height:  unset !important;
  height: unset !important;
}
.myModal {
    height: 100%;
    width:100vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.company-profile {
  padding: 100px;
  padding-left: 200px;
  padding-right: 200px;
}

.company-profile h5 {
  font-size: 25px;
  text-align: center;
}

.company-profile span {
  font-weight: 600;
  color: orange
}
</style>
