<template>
  <div class="margin-top">
    <div class="section section-team text-center ">
      <div class="container">
        <h2 class="title">Contact Us</h2>
        <div class="team">
          <div class="row">
            <div class="col-md-3">
              <div class="team-player">
                <h4 class="title">ADDRESS</h4>
                <p class="category text-primary">
                  <a
                    target="_blank"
                    href="https://www.google.com/maps/dir/Av+Reyes+Building,+Beaterio+St,+Intramuros,+Manila,+1002+Metro+Manila/Av+Reyes+Building/@14.5912568,120.9391237,13z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x3397ca17d993890f:0xfef0198efb80f8c7!2m2!1d120.9741432!2d14.5912594!1m5!1m1!1s0x3397ca17d993890f:0xfef0198efb80f8c7!2m2!1d120.9741432!2d14.5912594!3e2"
                    >Unit E and F AV Reyes Bldg. Beaterio Street Intramuros
                    Manila</a
                  >
                </p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="team-player">
                <h4 class="title">PHONES</h4>
                <p class="category text-primary">
                  <a href="Tel:#">+639171717999</a>
                </p>
                <p class="category text-primary">
                  <a href="Tel:#">+639770357107</a>
                </p>
                <p class="category text-primary">
                  <a href="Tel:#">70899407</a>
                </p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="team-player">
                <h4 class="title">EMAIL</h4>
                <p class="category text-primary">
                  <a href="mailto:#">admin@efuntrip.com</a>
                </p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="team-player">
                <h4 class="title">OPENING HOURS</h4>
                <p class="category text-primary">Mon–Fri: 8:00 am–5:00 pm</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-contact-us text-center">
      <div class="container">
        <h2 class="title">SEND INQUIRY</h2>
        <p class="description">Your feedback is important to us.</p>
        <div class="row">
          <div class="col-lg-6 text-center ml-auto mr-auto col-md-8">
            <fg-input
              class="input-lg"
              placeholder="Full Name..."
              v-model="name"
              addon-left-icon="now-ui-icons users_circle-08"
            >
            </fg-input>
            <fg-input
              class="input-lg"
              placeholder="Email Here..."
              v-model="email"
              addon-left-icon="now-ui-icons ui-1_email-85"
            >
            </fg-input>
            <div class="textarea-container">
              <textarea
                class="form-control"
                name="name"
                rows="4"
                cols="80"
                v-model="message"
                placeholder="Type a message..."
              ></textarea>
            </div>
            <div class="send-button">
              <n-button
                @click="sendEmail()"
                type="primary"
                round
                block
                size="lg"
                >Send Message</n-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, FormGroupInput } from "@/components";
import emailjs from "emailjs-com";
export default {
  name: "landing",
  bodyClass: "landing-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      name: "",
      email: "",
      message: "",
    };
  },
  methods: {
    sendEmail(e) {
      if (this.name && this.email && this.message) {
        try {
          emailjs.send("service_idxnooc", "template_5ywzack",{
            from_name: this.name,
            to_name: "funtrip",
            message: this.message,
            reply_to: this.email,
            to_email: "wycfuntriptravelandtours@gmail.com",
          }, 'zh64zHABraTa__drL');
        } catch (error) {
        //   console.log({ error });
        }
        // Reset form field
        this.name = "";
        this.email = "";
        this.message = "";
        alert('Your inquiry has been sent.')
      }else {
          alert('Please fill all fields')
      }
    },
  },
};
</script>
<style>
.margin-top {
    margin-top: 50px;
}
</style>
