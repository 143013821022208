<template>
  <div>
    <div class="section section-team text-center">
      <div class="container">
        <h2 class="title">Our Services</h2>
        <div class="team">
          <div class="row">
            <div class="col-md-3">
              <div class="team-player">
                <img
                  src="../../assets/images/airplane-ticket.png"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Air Tickets</h4>
                <!-- <p class="category text-primary">Model</p> -->
                <p class="description">
                  At our travel agency, you can book air tickets to any world destination.
                </p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="team-player">
                <img
                  src="../../assets/images/cruise.png"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Voyages & Cruises</h4>
                <p class="description">
                 Besides regular tours and excursions, we also offer a variety of cruises & sea voyages for different customers looking for awesome experiences.
                </p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="team-player">
                <img
                  src="../../assets/images/hotel.png"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Hotel Bookings</h4>
                <p class="description">
                  We offer a wide selection of hotel ranging from 5-star ones to small properties so that you could book a hotel you like.
                </p>
        
              </div>
            </div>
            <div class="col-md-3">
              <div class="team-player">
                <img
                  src="../../assets/images/vacations.png"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Tailored Summer Tours</h4>
                <p class="description">
                  Our agency provides varied tours including tailored summer tours for clients who are searching for an exclusive and memorable vacation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, FormGroupInput } from '@/components';
export default {
  name: 'landing',
  bodyClass: 'landing-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      form: {
        firstName: '',
        email: '',
        message: ''
      }
    };
  }
};
</script>
<style></style>
