<template>
  <div class="tour-container">
    <h3>TOURS AND PACKAGES</h3>
    <div class="packages-container">
          <div
            class="package-item"
            v-for="(pk, index) in packages"
            :key="index"
          >
            <div class="package-image">
              <img :src="`http://api.efuntrip.com/${pk.filePath}`" :alt="pk.filePath" @click="showSingle(pk.package_url)" />
            </div>
            <div class="package-footer">
              <h5 class="package-title">{{ pk.package_name }}</h5>
              <h5 class="package-price">
                as low as Php <span>{{ pk.package_price }}</span>
              </h5>
            </div>
            <div v-html="pk.package_description"></div>
          </div>     
    </div>
        <div v-if="visible" class="myModal">
            <div class="img-container">
                <img :src="popupImg" tag="" />
                <div class="btn-close" @click="closeModal">close</div>
            </div>
        </div>
  </div>
</template>
<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  components: {
    // Carousel,
    // Slide,
  },
  data() {
    return {
      packages: [],
      popupImg: '',
      visible: false
    };
  },
  methods: {
      showSingle(url) {
          this.popupImg = url;
          this.visible = true;
      },
      closeModal() {
          this.visible = false;
      }
  },
 created() {
    //  fetch("http://api.efuntrip.com/api/v1/funtrip/get-packages").then(async(res) => {

    fetch("http://api.efuntrip.com/api/v1/funtrip/get-packages").then(async(res) => {
      const pk = await res.json();
      this.packages = pk.data;
    });
  },
};
</script>
<style>
@media screen and (max-width: 1200px) {
  .package-item {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    margin: 5px;
    padding: 20px;
    flex-grow: 1 !important;
    flex: 100% !important;
  }
}
.tour-container {
  margin-top: 100px;
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.packages-container {
  /* height: 400px; */
  display: flex;
  width: 95vw;
  flex-wrap: wrap;
  justify-content: space-around;
}

.package-image {
  background-image: cover;
  flex-grow: 1;
  height: 300px;
  overflow: hidden;
}

.package-image img {
  background-image: cover;
  width: 100%;
}
.package-item {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin: 5px;
    padding: 20px;
  flex: 0 0 32%;
  border-radius: 10px;
  height: 600px;
  overflow: auto;
}
.package-title {
  font-weight: 600;
  font-size: 25px;
}
.package-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.package-price {
  font-size: 25px;
  color: orange;
}
.package-price span {
  font-weight: 600;
}
.img-container {
    height: 700px;
    max-width: 500px;
     background-size: contain;
     object-fit: contain;
     position: relative;
}
.btn-close {
    height: 40px;
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200000;
    position: absolute;
    top: 10px;
    right: 20px;
    background-color: brown;
    color: #ffffff;
    border-radius: 10px;
    cursor: pointer;
}
.img-container img {
    background-size: contain;
    object-fit: contain;
}
</style>
